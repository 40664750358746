import React from 'react'
import axios from 'axios'

import {useState} from 'react'
import {useEffect} from 'react'

import CustomDatePicker from './CustomDatePicker'
import NumericInput from 'react-numeric-input';
import ReactTouchEvents from "react-touch-events";
import Htmap from './htmap'



import Grapher from './Grapher'
import Uppskattning from './Uppskattning'
import Dagspris from './Dagspris'

const URL = "https://el.maxn.se/priceusage"
const URL2 = "https://el.maxn.se/sumelpris"

//const URL = "http://localhost:8800/priceusage"
var DISPLAY_MONTH = false;
var DISPLAY_YEAR = false;

var CURR_MONTH = new Date().getMonth();
var MAX_MONTH = CURR_MONTH;
var MAX_YEAR = 2025;
var MIN_YEAR = 2022;
var CURR_YEAR = null;


function diffInDays(startDay, endDay){
  let difference_In_Time = endDay.getTime() - startDay.getTime();
  return  parseInt(difference_In_Time / (1000 * 3600 * 24));
}

function formatKwh(num) {
  return num + ' kWh';
}

function formatCost(num) {
  return num + ' öre';
}


function selectedDiff(startDay, endDay){
  if(DISPLAY_YEAR){
    return CURR_YEAR
  }
  if(!DISPLAY_MONTH){
    let OUT_STRING = " dag"
    let out = diffInDays(startDay, endDay) 
  
    if (out > 1){
      OUT_STRING+="ar"
    }
    return out + OUT_STRING
  }
  

  var months = ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"]; 
   return months[CURR_MONTH]

}

  function resetTime(date){
    var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    return newDate;
  }

  let MIN_DATE = "";
  let MAX_DATE = "";

  let MAX_DATE_UNIX;
  let MIN_DATE_UNIX;

  function drawGraph(val){
    let cost = []//[10, 50]
    let usage = []//[32,100]
    let datetime = []
    
    val.forEach((element) => {
      element.hours.forEach((hour) =>{
        cost.push(parseFloat((hour.sek * 100).toFixed(4)))
        usage.push(hour.kwh)
        let time = hour.time
        time = String(time)
        time = time.padStart(2,'0')
        datetime.push(element.date.split("T")[0] +"T" + time +":00:00")
      });
 
      });
    return [cost, usage, datetime]
    }

let elpris;


export const Graph = () => {


  const [startDay, setStartDate] = useState(new Date());
  const [endDay, setEndDate] = useState(new Date());
  const [currSpan, setSpan] = useState([]);
  
  //const [elpris, setElpris] = useState()
  const [graphData, setGraphData] = useState({
    usage:[],
    cost:[],
    dates:[]
  })
  const [days2, setDays] = useState([]);


  useEffect(()=>{
    const fetchAllElPris = async ()=>{

        try{
            const res2 = await axios.get(URL2)
            let fetchedDays = res2.data;
            setDays(fetchedDays)

            const res = await axios.get(URL)
            let days = res.data;
            MAX_DATE = days.slice(-1)[0].date;

            MIN_DATE = days[0].date;
      
            let end = new Date(MAX_DATE);
            end.setDate(end.getDate()+1)
            MAX_DATE = new Date(end);
            let minD =  new Date(MIN_DATE);
            setEndDate(end);
            setStartDate(minD);
            MIN_DATE = minD;

            MIN_DATE_UNIX = minD.getTime();
            MAX_DATE_UNIX = MAX_DATE.getTime();



            var result = days.reduce(function(map, obj) {
                map[obj.date] = obj.hours;
                return map;
            }, {});

                        

            //setElpris(result)
            elpris = result;

            //displayDays(MIN_DATE, MAX_DATE)
            displayLast(7);
            //displayDays(new Date(2022-12-31T00:00:00"), new Date())
            


   
        }catch(err){
            console.log(err)
        }
    }
    fetchAllElPris()
  }, [])






  function updateStartDate(date) {
    let curr = new Date(date);

    if(curr.getTime() < MIN_DATE_UNIX){
      curr = new Date(MIN_DATE_UNIX)
    }
      setStartDate(resetTime(curr))

  }

  function updateEndDate(z) {
    let date = new Date(z);


    if(date.getTime() > MAX_DATE_UNIX){
      date = new Date(MAX_DATE_UNIX)
    }
    setEndDate(resetTime(date))
  }

  function updateDates(dates) {
    if(dates==="nope"){
      return
    }
    let newStart = dates[0]
    let newEnd = dates[1]

    updateStartDate(newStart);
    updateEndDate(newEnd);

    displayDays(newStart, newEnd);
  }

  function formatDate(currDay){
    return currDay.getFullYear() + "-" + String((currDay.getMonth()+1)).padStart(2, '0') + "-" + String((currDay.getDate())).padStart(2, '0');

  }

function displayLast(n){
  DISPLAY_MONTH = false;
  DISPLAY_YEAR = false;

  let endDay = new Date(MAX_DATE);
  let startDay = new Date(endDay);

  startDay.setDate(startDay.getDate() - n)
  startDay = formatDate(startDay)
  endDay = formatDate(endDay)

 
  displayDays(startDay,endDay, true)




}


function displayDays(startDay, endDay, update=false, month=false, year=false){
  //DISPLAY_MONTH = month;
  //DISPLAY_YEAR = year;
  //console.log({DISPLAY_MONTH}, {DISPLAY_YEAR})
  /*
  if(month){
    DISPLAY_MONTH = true;
  }else{
    DISPLAY_MONTH = false;
  }
  */

  let targets = []
  let end = new Date(endDay);
  let start = new Date(startDay);
  let temp = new Date(startDay);

  for(let currDay = new Date(Date.UTC(temp.getFullYear(), temp.getMonth(), temp.getDate())); currDay <= end; currDay.setDate(currDay.getDate() + 1)){
    let z  = formatDate(currDay)

    if(elpris[z]){

      targets.push({"date" :z, "hours": elpris[z]});

    }

  }

  if(update){
    start.setHours(0,0,0)
    end.setHours(0,0,0)
    
    updateStartDate(start);
    updateEndDate(end);
  }

  graphDays(targets)
  setSpan(targets)


}

function graphDays(days){



  let temp = drawGraph(days)
  let ccost = temp[0]
  let cusage = temp[1]
  let cdatetime = temp[2]

  setGraphData({
    cost:ccost,
    usage:cusage,
    dates:cdatetime
  })



}

const  limitCost = () =>{
  setCostIn(cost);
}



const [cost, setCost] = useState(0);
const [costIn, setCostIn] = useState(0);


const updateCost = (event) => {
 setCost(event);
};

const  limitKwh = () =>{
   setKwhIn(kwh);
}



const [kwh, setKwh] = useState(0);
const [kwhIN, setKwhIn] = useState(0);


const updateKwh = (event) => {
  setKwh(event);
};


const handleFocus= (event) => {
  if (event.target instanceof HTMLInputElement){
    event.target.select();

  }
}


function displayMonth(month,year, changeYear, left){
  DISPLAY_YEAR = false;
  DISPLAY_MONTH = true;
  let cy = 0
  if(changeYear){
    cy = 1
    if(left){
      cy*=-1
    }
  }
  var startDay = new Date(year + cy, month, 1, 0, 0, 0, 0);
  var endDay = new Date(year + cy, month + 1, 1, 0, 0, 0,0);
  CURR_MONTH = month
  displayDays(startDay, endDay, true, true);
}

function displayYear(year, changeYear, left, reset=false){
  if(reset){
    CURR_YEAR = MAX_YEAR
  }
  
  if(year >= MAX_YEAR -1 && !left){
    year = MAX_YEAR
    changeYear = false
  }
  if(year <= MIN_YEAR +1 && left){
    year = MIN_YEAR
    changeYear = false
  }
  
  DISPLAY_MONTH = false;
  DISPLAY_YEAR = true;
  let cy = 0
  if(changeYear){
    cy = 1
    if(left){
      cy*=-1
    }
    year = year+cy

  }
  var endDate = new Date(year,11,32,0,0,0,0)

  var startDay = new Date(year , 0, 1, 0, 0, 0, 0);
  
  if(year === MAX_YEAR){
    endDate = new Date()
  }

  CURR_YEAR = year

  displayDays(startDay, endDate, false, true);
}



function moveDates(startDay,endDay, left){
  let changeYear = false;
  if(DISPLAY_MONTH){
    if(left){
      CURR_MONTH-=1
      if(CURR_MONTH < 0){
        CURR_MONTH = 11
        changeYear = true;
      }
      if(!changeYear && CURR_MONTH < 0){
        CURR_MONTH = 0
      }

    }else{
                      
      CURR_MONTH+=1
      if(CURR_MONTH > 11){
        CURR_MONTH = 0;
        changeYear = true;

      }
      if(!changeYear && CURR_MONTH > MAX_MONTH && startDay.getYear() == MAX_YEAR){
        CURR_MONTH = MAX_MONTH
      }
        
    }
    displayMonth(CURR_MONTH, startDay.getFullYear(), changeYear, left);
    return "nope"
  }else if(DISPLAY_YEAR){
    
    displayYear(CURR_YEAR,true, left, false)
    return "nope"
  }else{

  
  let dist = diffInDays(startDay,endDay);

  if(left){
    let distToMin = diffInDays(new Date(MIN_DATE), startDay)

    if(distToMin - dist <= 0){
      startDay = new Date(MIN_DATE);
      endDay = new Date(startDay.getTime());
      endDay.setDate(endDay.getDate() + dist);
      startDay = resetTime(startDay);
      endDay = resetTime(endDay);
      return [startDay, endDay];
    
    }else{
      startDay.setDate(startDay.getDate() - dist);
      endDay.setDate(endDay.getDate() - dist);
    }





  }else{

    let distToMax = diffInDays(endDay, new Date(MAX_DATE))
    if(distToMax < dist){
      
      endDay = new Date(MAX_DATE);
      startDay = new Date(endDay.getTime())
      startDay.setDate(startDay.getDate() - dist)
      return [startDay, endDay]
    }


    startDay.setDate(startDay.getDate() + dist);
    endDay.setDate(endDay.getDate() + dist);

  }

  return [startDay, endDay]
}

}
if (days2.length === 0) {
  return <div>Loading...</div>;
}
let START_DATE = days2[0].date
let END_DATE = days2[days2.length - 1].date

const zkr = days2.map(({ date, kr }) => ({ date, data: parseInt(kr/100) })); //0 27
const zkwh = days2.map(({ date, kwh }) => ({ date, data: parseInt(kwh/100) })); //0 15
  return (
    
    <div>
      
    <div id="quickButton" className="centered">
    <br></br>

    <div>
    <Uppskattning data={currSpan}/>


    <div id="quickButtons">
    
      <button onClick={() => displayLast(1)}>1</button>

      <button onClick={() => displayLast(3)}>3</button>

      <button onClick={() => displayLast(7)}>7</button>

      <button onClick={() => displayMonth(MAX_MONTH, MAX_YEAR, false, false)}>Mån</button>

      <button onClick={() => displayYear(MAX_YEAR,false,false,true)}>År</button>

      <button onClick={() => displayDays(MIN_DATE, MAX_DATE)}>Allt</button>

      


    </div>

    </div>


    </div>
    <div className="parent">
      <div className="left"><NumericInput  onFocus={handleFocus} value={kwh} format={formatKwh} onChange={updateKwh}></NumericInput> <button onClick={limitKwh}>LÅS</button></div>
      <div className="right"> <button onClick={limitCost}>LÅS</button><NumericInput value={cost} format={formatCost} onChange={updateCost} onFocus={handleFocus}></NumericInput></div>
    </div>

        <div id="chart">
        <Grapher usage={graphData.usage} cost={graphData.cost} dates={graphData.dates} costLimit={costIn} kwhLimit={kwhIN}></Grapher>
        </div>
        <div id ="datepicker" className="centered">
          <div>
          From <CustomDatePicker  inDate={startDay} update={(date) => updateStartDate(date)}  ></CustomDatePicker>
          </div>
          <div>
          To <CustomDatePicker  inDate={endDay} update={(date) => updateEndDate(date)} ></CustomDatePicker>

          </div>
        </div>

        <div className="centered">
          <button className="leftright" onClick={() => updateDates(moveDates(startDay,endDay, true))}>{ "<" } </button>
          <div>
          <button id="display" onClick={() => displayDays(startDay, endDay,false,false)}>Draw graph</button>

          <p>{selectedDiff(startDay, endDay)}</p>

          </div>
          
          <button className="leftright" onClick={() => updateDates(moveDates(startDay,endDay, false))}>{ ">" } </button>
        </div>
    

    <Dagspris usage={graphData.usage} cost={graphData.cost} dates={graphData.dates}></Dagspris>

    </div>
    

  )

  
}

export default Graph